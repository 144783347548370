/* ==========================================================================
   Modals
   ========================================================================== */

/**
 * Part of workaround for Bootstrap bug where content shifts when modal
 * is opened.
 *
 * https://github.com/twbs/bootstrap/issues/9855

 */
.modal { z-index: 9999; }

.modal-backdrop.in { z-index: 9001;}

.modal-content {
  padding: 0;
  background: $gray-50;
}

.modal-open {
  overflow: auto;
}

.close {
  font-size: 14px;
  margin-top: 0px;
  height: 55px;
  width: 55px;
}

.modal-header {
  margin-bottom: 0;
  background: $gray-100;
  overflow: hidden;
  border-radius: 4px 4px 0 0;
}

.modal-title {
  font-weight: 500;
  padding:15px;
}

.modal-title + .header-nav {
  margin-top: -5px;
}


.modal-header, .modal-footer {
  padding: 0;
}

.modal-footer {
  padding: 0 15px 15px;
  border: 0;
  margin-top: 0;
  input[type="checkbox"] { float: none; }
}

.modal-dialog.modal-lg {
  width: 100%;
}

/* Media queries
   ========================================================================== */
@media (max-width: $screen-xs) {
  .modal-header {
    .modal-title {  }
    .close { padding: 15px;}
  }
  .modal-footer {
    .btn-fixed-width {
      width: 96px;
    }
  }
}

@media (min-width: $screen-sm-min) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
  .modal-content {
    -webkit-box-shadow: 0 5px 15px $shadow-500;
            box-shadow: 0 5px 15px $shadow-500;
  }
  .modal-sm {
    width: 300px;
  }
}
