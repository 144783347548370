@mixin background-image-retina($file, $type, $width, $height) {
  background-image: url($file + '.' + $type);

  @media (-webkit-min-device-pixel-ratio: 2), (-moz-min-device-pixel-ratio: 2) {
    & {
      background-image: url($file + '-2x.' + $type);
      -webkit-background-size: $width $height;
    }
  }
}

// Breakpoints
// Use for self-writing media queries
@mixin breakpoint($break) {
  $xs: 36rem; // 576px and below
  $sm: 48rem; // 768px and below
  $md: 64rem; // 1024px and below
  $lg: 80rem; // 1280px and below
  $xl: 90rem; // 1440px and above

  @if $break == xs {
    @media (max-width: $xs) { @content; }
  }

  @else if $break == sm {
    @media (max-width: $sm) { @content; }
  }

  @else if $break == md {
    @media (max-width: $md) { @content; }
  }

  @else if $break == lg {
    @media (max-width: $lg) { @content; }
  }

  @else if $break == xl {
    @media (min-width: $xl) { @content; }
  }

  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg, xl";
  }
}

// Hide Things Accessibly
// Parent element should usually have position: relative;
@mixin hidden {
  left: -10000px;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
}
@mixin visible {
  position: static;
  height: auto;
  width: auto;
}

// Add icons to things!
$solid: 900;
$regular: 400;
// $thin: 300; //FA Pro Only

@function fa-content($fa-var) {
  @return unquote("\"#{ $fa-var }\"");
}
@mixin fa-icon {
  font-family: 'Font Awesome 5';
  font-style: normal;
  text-rendering: optimizeLegibility;
}
@mixin icon($fa-icon, $icon-weight) {
  &::after {
    @include fa-icon;
    content: fa-content($fa-icon);
    font-weight: $icon-weight;
    margin: 0 auto;
  }
}

//Icon Mappings
//Create a new mixin for each icon, then include the mixin to the class where you want the icon to appear
@mixin icon-plus { @include icon($fa-var-plus, $solid) }
@mixin icon-gear { @include icon($fa-var-gear, $solid) }
@mixin icon-toggle-collapsed { @include icon($fa-var-chevron-down, $solid) }
@mixin icon-toggle-expanded { @include icon($fa-var-chevron-up, $solid) }