.purchase-app {
  // Temporary for the review purchase page
  .btn.btn-xs {
    min-height: auto;
    padding: 2px 12px;
  }

  .purchase-sideways-panels {
    @extend .stack-md-sideways-panels;

    margin-left: 0px;
    margin-right: 0px;

    >:first-child {
    }

    >:last-child {
      @extend .mx-auto;

      max-width: 363px;
    }

    @media (min-width: $screen-md) {
      >:first-child {
        width: 100% !important;
      }

      >:last-child {
        @extend .mx-lg-0;
        @extend .ml-lg-5;

        width: 363px;
        min-width: 363px;
      }
    }
  }

  .purchase-total {
    font-size: 18px;
    font-weight: bold;
  }

  .madero-style.alert.price-update {
    padding: 10px;
  }

}

.btn-group-justified.payment-method-select {
  @extend .btn-group-justified, .stacking;

  width: 400px;

  button {
    width: 200px;
    font-weight: normal;
  }

  streamline-icon {
    @extend .ml-3;
  }
}

.btn-group-justified.billing-period-select {
  @extend .btn-group-justified, .stacking;

  width: 300px;

  button {
    width: 150px;
    font-weight: normal;
  }

  streamline-icon {
    @extend .ml-3;
  }
}

.StripeElement {
  padding: 11px 12px;
}

.purchase-footer {
  text-align: center;

  .title {
     font-weight: bold;

     svg {
      fill: $gray-500;
      vertical-align: middle;
      margin-left: 5px;
     }
  }
}

.checkout-plan-picker {
  .brandscript-panel {
    @media (min-width: $screen-md) {
      width: 320px;
      min-width: 320px;
      margin-right: 60px;
    }
  }

  @media (max-width: 492px) {
    overflow-x: scroll;
  }

  .testimonial {
    margin-bottom: 50px;
    > div {
      position: relative;
      padding: 55px 30px;
      &::before,
      &::after {
        content: "“";
        position: absolute;
        font-size: 48px;
        font-weight: 700;
        line-height: 125%;
      }
      &::before {
        top: 17px;
      }
      &::after {
        transform: rotate(180deg);
        bottom: 27px;
        right: 34px;
      }
      img {
        position: absolute;
        right: 31px;
        bottom: -32px;
        border-radius: 50%;
      }
      span {
        position: absolute;
        left: 0;
        bottom: -38px;
        height: 30px;
        width: 75%;
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
        color: $black;
      }
    }
  }

  .btn-group-period,
  .btn-group-yesno {
    .btn {
      font-weight: normal;
    }
  }

  .btn-group-yesno {
    width: 175px;
    min-width: 150px;
  }

  .pricing-calculator {
    .number {
      font-size: 24px;
      font-weight: bold;
      del {
        font-weight: normal;
      }
    }
  }

  .label-primary {
    padding-left: 14px;
    padding-right: 14px;
    background: $rise-blue;
    font-size: 14px;
    line-height: 20px;
    border-radius: 20px;
  }

  .checkmark {
    svg {
      fill: $rise-green;
      margin-top: 5px;
      height: 19px;
      width: 23px;
    }
  }

  #pricing-plan-grid {
    > div {
      padding-left: 12px;
      padding-right: 12px;
    }

    .disabled, .disabled .text-blue {
      color: $gray-500;
    }

    .u_clickable .disabled,
    .disabled .u_clickable,
    .disabled.u_clickable,
    .disabled label::before {
      cursor: default;
    }

    > div.spacer {
      border: none !important;
      height: 0;
      line-height: 0;
      overflow: hidden;
    }

    > div.selected {
      padding-left: 10px;
      padding-right: 10px;
      border-left: 2px solid $rise-blue;
      border-right: 2px solid $rise-blue;
    }

    > div.top-grid-row {
      padding-top: 2px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      &.selected {
        padding-top: 0px;
        border-top: 2px solid $rise-blue;
      }
    }

    > div.bottom-grid-row {
      padding-bottom: 7px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      &.selected {
        padding-bottom: 5px;
        border-bottom: 2px solid $rise-blue;
      }
    }

    .text-blue {
      color: $rise-blue;
    }

    .badge-best-value {
      background-color: $rise-blue;
      position: absolute;
      top: -30px;
      left: calc(50% - 2.9em);
    }

    .badge-current-plan {
      background-color: $gray-500;
      position: absolute;
      top: -30px;
      left: calc(50% - 3.6em);
    }

    @media (min-width: $screen-md) {
      > div {
        padding-left: 22px;
        padding-right: 22px;
      }

      > div.selected {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    @media (max-width: $screen-sm) {
      > div.spacer {
        height: auto;
        line-height: 3;
        overflow: visible;
      }
    }
  }

}

.display-count-input {
  @extend .form-control;

  width: 75px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  display: inline;

  &.text-md {
    @extend .text-md;
    font-weight: normal;
  }
}

.quantity {
  width: 211px;
  margin: 0 auto;
  .input-group-btn {
    > .btn {
      width: 40px;
      height: 40px;
      border: 1px solid $gray-400;
    }
  }

  .btn-secondary {
    background: $white;
    color: $rise-blue;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    &:hover {
      background: $rise-blue;
      color: $white;
    }
  }
}

.k12-schools-content {
  ul {
    margin-left: 2em;
    li {
      position: relative;
      padding-left: 20px;
      padding-bottom: 10px;
      .checkmark {
        position: absolute;
        top: 0;
        left: -28px;
      }
    }
  }
}

.logos {
  .logo {
    display: inline-block;
    margin: 0 6px 6px 0;
    img {
      width: auto;
      height: 60px;
    }
  }
}

// OLD CHECKOUT FLOW - REMOVE
.subscription-summary {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.subscription-summary-item {
  flex-grow: 1;
  width: 45%;
}

.subscription-summary-divider {
  flex-grow: 0;
  width: 10%;
  align-self: center;
  font-weight: 900;
  font-size: 2em;
}

.stat-value {
  font-size: 4em;
}



.flex-additive-rule {
  height: 32px;
  b {
    font-size: 33px;
    position: relative;
    top: -28px;
    background: $gray-50;
    border: 10px solid $gray-50;
  }
}


.payment-recurrence-selector {
  padding: 0;
  .radio {
    display: flex;
    flex-direction: row;
    position: relative;
    min-height: 20px;
    padding: 9px;
    margin: 0;
    .label {
      margin-left: auto;
      font-size: 1em;
    }
    &.active {
      background: aliceblue;
    }
  }
}

.spinner {
  border-radius: 4px;
  input {
    width: 50px !important;
    font-size: 18px;
    border-radius: 4px !important;
    text-align: center;
  }
  .input-group-btn-vertical {
    position: relative;
    white-space: nowrap;
    width: 1%;
    vertical-align: middle;
    display: table-cell;
    &>.btn {
      display: block;
      float: none;
      width: 100%;
      max-width: 100%;
      padding: 8px;
      margin-left: -1px;
      position: relative;
      border-radius: 0;
      &:first-child {
        border-top-left-radius: 4px;
      }
      &:last-child {
        margin-top: -2px;
        border-bottom-left-radius: 4px;
      }
    }
    i {
      position: absolute;
      top: 0;
      left: 4px;
    }
  }
}