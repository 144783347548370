.rise-powerbi-container {

  // 100% width for mat-button-toggle-group
  mat-button-toggle-group {
    width: 100%;
    display: flex;
  }

  mat-button-toggle {
    flex: 1;

    ::ng-deep .mat-button-toggle-label-content {
      width: 100%;
      text-align: center;
    }
  }
}