.rise-text-container {
  height: calc(var(--attribute-list-height) - var(--back-button-header-height));
  padding-bottom: 20px;
}

@media (max-width: $screen-sm) {
  .rise-text-container {
    height: auto;
  }
}

.font-picker {
  position: relative;
  display: inline-block;
  width: 100%;
  background-color: $white;
  border: 1px solid $gray-500;
  border-radius: 3px;
  padding: 8px;
  cursor: pointer;
}
.font-picker-selected {
  font-size: 14px;
  padding-top: 3px;
}
.font-picker-icon {
  font-size: 10px;
}
.font-picker-dropdown {
  position: absolute;
  top: 45px;
  left: 0;
  width: 100%;
  z-index: 10;
  overflow-y: auto;
  background-color: $white;
  border: 1px solid $gray-500;
  border-radius: 3px;
  padding: 5px;
}
.font-picker-option {
  padding: 5px;
  cursor: pointer;
}
.font-picker-option:hover {
  background-color: $gray-400;
}
.font-picker-option.selected {
  background-color: $gray-100;
}

.formatting-options {
  display: flex;
  justify-content: space-between;
  border: 1px solid $gray-500;
  border-radius: 4px;
  height: 40px;

  .formatting-option {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    padding: 5px;
    cursor: pointer;
  }

  .formatting-option:hover {
    background-color: $gray-400;
  }

  .formatting-option.selected {
    background-color: $rise-blue;
    color: $white;
  }

  .formatting-option.middle {
    border-left: 1px solid $gray-500;
    border-right: 1px solid $gray-500;
  }
}






