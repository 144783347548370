/* Standard Table
   ========================================================================== */

.table {
    width: 100%;
    margin-bottom: 0;
    .table-header {
        .table-header__row > .table-header__cell {
            > a {
                color: $gray-800;
                display: block;
            }
            label {
                margin: 0;
            }
        }
    }
    .table-body {
        .table-body__row:not(.clickable) {
            &:hover {
                a:not(.btn) {
                    color: $rise-blue;
                }
            }
            & > .table-body__cell {
                vertical-align: middle;
                padding: 12px 8px;
                -webkit-touch-callout: none;
                &:first-child {
                    input {
                        display: inline-block;
                    }
                    span a {
                        display: inline-block;
                        vertical-align: middle;
                        overflow: hidden;
                    }
                }
                &:first-child:before {
                    font-family: FontAwesome;
                    font-style: normal;
                    margin-right: 10px;
                    display: inline-block;
                    vertical-align: top;
                    color: $gray-300;
                    font-size: 16px;
                    width: 16px;
                    height: 18px;
                    text-align: center;
                }
                > a {
                    display: block;
                }
                > span {
                    display: inline-block;
                    vertical-align: middle;
                }
                > span.baseline {
                    display: inline-block;
                    vertical-align: baseline;
                }
                &.table-body__cell_highlight {
                    background-color: $rise-premium;
                    font-weight: bold;
                }
            }
        }
    }
    //end .table-body
    &.table--hover {
        @extend .table-hover;
        .table-body {
            .table-body__row {
                & > .table-body__cell {
                    cursor: pointer;
                }
            }
        }
    }
    //end .table--hover
    &.table--sortable {
        .table-body {
            .table-body__row > .table-body__cell {
                cursor: move;
            }
            .table-body__row:hover > .table-body__cell {
                background: $gray-100;
            }
        }
    }
    //end .table--sortable
    &.table--selector {
        .table-body {
            .table-body__row {
                & > .table-body__cell {
                    cursor: pointer;
                    &:first-child i {
                        margin: 0 4px;
                    }
                }
                &.active {
                    &:hover {
                        .table-body__cell {
                            background: $gray-100;
                        }
                    }
                    .table-body__cell {
                        background: $gray-200;
                    }
                }
                &.table-body__row--disabled {
                    .table-body__cell,
                    .table-body__cell a {
                        cursor: not-allowed;
                        color: $gray-500;
                        background: $gray-100;
                    }
                    &:hover {
                        a {
                            color: $gray-500;
                        }
                    }
                }
                &.table-body__row--no-select {
                    .table-body__cell {
                        .flex-row {
                            padding-left: 31px;
                        }
                    }
                }
                &.table-body__row--blocked-file {
                    > .table-body__cell {
                        background: $gray-100;
                        cursor: pointer;
                        position: relative;
                        .callout {
                            top: 45px;
                            left: 0;
                            }
                        }
                    }
                }
            }
            //end .table-body
        &.table--back-btn {
            .table-body__cell {
                &:first-child:before {
                    content: '\f060' !important;
                }
            }
        }
        &.table--multiple-selector {
            > .table-body > .table-body__row.info-row {
                background-color: $gray-200;
                .table-body__cell:first-child {
                    &:before {
                        content: initial;
                    }
                }
            }
        } //end table--multiple-selector

    } //end .table--selector
    &.table--data-list {
         tbody > tr > td {
                border: none;
                }
         .table-body .table-body__row > .table-body__cell {
                padding: 2px 8px;
                }
    }
    .row--disabled  {
      background-color: $gray-200;
      opacity: .5;
      cursor: not-allowed !important;
    }
    &.table--disabled {
         .table-body .table-body__row:not(:first-child)  {
                @extend .row--disabled
                }
    }
    &.table--schedule-list {
         .table-body .table-body__row > .table-body__cell {
                > span {
                display: inline;
                vertical-align: unset;
                }
        }
    }

} // end .table

/* Presentation Selector */

//TODO: No references in current Presentation Selector in either Schedules or Alerts
.presentation-selector {
    margin-top: 20px;
    /* Styled the same as navbar-left. */
    .selector-header {
        /* Positioning */
        float: left;
        /* Display & Box Model */
        width: 100%;
        padding: 5px 15px 5px 0;
        border-bottom: $gray-300;
        margin: 0;
        li {
            /* Positioning */
            float: left;
            /* Display & Box Model */
            display: block;
            margin-left: 5px;
        }
        a {
            /* Display & Box Model */
            display: block;
            padding: 3px 10px;
            border: 1px solid $white;
            /* Visual */
            font-weight: bold;
            line-height: 32px;
            color: $gray-700;
        }
        a:hover,
        .active > a,
        .active > a:hover,
        .active > a:focus {
            /* Display & Box Model */
            border: 1px solid $gray-200;
            /* Visual */
            color: $rise-blue;
            background-color: $gray-100;
        }
        .close-button {
            float: right;
        }
        .close {
            line-height: 32px;
        }
    }
    .search {
        margin-bottom: 20px;
    }
    .panel-body {
        clear: both;
    }
}
