// //== Iconography
// //

// //** Load fonts from this directory.
// $icon-font-path: "../fonts/";
// //** File name for all font files.
// $icon-font-name:          "fontawesome-webfont";
// //** Element ID within SVG icon file.
// $icon-font-svg-id:        "fontawesome-webfont";

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
// Font Stacks
$inter: 'Inter', Helvetica, Arial, sans-serif;