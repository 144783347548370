.dropdown {
  .dropdown-menu {
    :not(.dropup) {
      top: 120%;
      left: auto;
      right: 0;
    }
    padding: 0;
    overflow: hidden;
    >li {
      >a {
        padding: 8px;
      }
    }
    .divider {
      @include nav-divider($dropdown-divider-bg, 0);
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
  > .dropdown-menu::before,
  > .dropdown-menu::after {
    position: absolute;
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid $gray-400;
    border-bottom-color: $shadow-200;
    content: '';
  }
  > .dropdown-menu::before {
    top: -7px;
    right: 9px;
  }
  > .dropdown-menu::before,
  > .dropdown-menu::after {
    top: -7px;
    right: 9px;
  }
  > .dropdown-menu::after {
    top: -6px;
    right: 10px;
    border-left-width: 6px;
    border-right-width: 6px;
    border-bottom-width: 6px;
    border-bottom-color: $white;
  }
  .dropdown-menu ul > li > a,
  .action-sheet ul > li > a {
    color: $gray-800;
    display: block;
    padding: 10px;
    margin: 0;
  }
  .dropdown-menu ul > li > a:hover,
  .dropdown-menu ul > li > a:focus {
    background-color: $pale-shadow-100;
    color: $rise-blue;
    text-decoration: none;

    .item-name {
      text-decoration: underline;
    }
  }
}
