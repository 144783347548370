.clr-picker {
  display: none;

  > input.clr-color {
    border-radius: 4px;
  }
}

.clr-field {
  width: 100%;

  & > button {
    border-radius: 50%;
    width: 24px !important;
    height: 24px !important;
  }

  & > button:hover {
    cursor: pointer;
  }
}

.clr-picker.clr-open, .clr-picker[data-inline="true"] {
  z-index: 10000;
}

