/* ==========================================================================
   Font Style
   ========================================================================== */

a[data-wysihtml5-command=bold],
a[data-wysihtml5-command=italic],
a[data-wysihtml5-command=underline],
a[data-wysihtml5-command=bold]:hover,
a[data-wysihtml5-command=italic]:hover,
a[data-wysihtml5-command=underline]:hover {
  /* Display & Box Model */
  min-width: 50px;
  /* Visual */
  background-color: $white;
  border-color: $gray-400;
}

a[data-wysihtml5-command=bold].active,
a[data-wysihtml5-command=italic].active,
a[data-wysihtml5-command=underline].active {
  /* Visual */
  -webkit-box-shadow: inset 0 2px 4px $shadow-150, 0 1px 2px $shadow-50;
  -moz-box-shadow: inset 0 2px 4px $shadow-150, 0 1px 2px $shadow-50;
  box-shadow: inset 0 2px 4px $shadow-150, 0 1px 2px $shadow-50;
  background-color: $gray-300;
  outline: 0;
}

a[data-wysihtml5-command=bold].btn {
  @extend .bold;
}

a[data-wysihtml5-command=italic].btn {
  @extend .italic;
}

a[data-wysihtml5-command=underline].btn {
  @extend .underline;
}

/* Font style classes */

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.text-pill {
  background-color: $rise-green;
  color: $white;
  padding: 0px 8px 2px 8px;
  border-radius: 4px;
  margin-right: 4px;
}
