.breadcrumb {
	font-size: 14px;
    padding: 7px 10px;
	margin-bottom: 0px;
	overflow: hidden;
	white-space: nowrap;
	background: none;
	>li {
		vertical-align: top;
		a, span {
			max-width: 100px;
			text-overflow: ellipsis;
			overflow: hidden;
			display: inline-block;
			vertical-align: top;
		}
	}
}

.breadcrumb {
	@media only screen and (min-width : $screen-sm) {
		>li {
			a,span {
				max-width: 240px;
			}
		}
	}
	@media (min-width: $screen-lg) {
	}
}

// .storage-app {
//     .breadcrumb {
        
//     }
// }
