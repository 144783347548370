.alert
{
    &.alert-premium
    {
        @include alert-variant($alert-premium-bg, $alert-premium-border, $alert-premium-text);
    }
    &.alert-plan
    {
        font-size: 13px;
        margin: 0 !important;
        padding: 6px;
        color: $black;
        border-radius: 0;

        &.plan-active
        {
            border-color: $green-150;
            background-color: $green-100;
        }
        &.plan-suspended
        {
            background-color: $amber-50;
            border-color: $amber-100;
        }
    }
    &.alert-lighter-gray
    {
        @include alert-variant($gray-100, $gray-100, $black);
    }
}

.madero-style {
    &.alert {
        background-color: $white;
        padding: 20px;

        streamline-icon {
            margin-right: 1rem;
            height: 16px;
        }

        mat-icon {
            padding-top: 0.3rem;
        }

        &.alert-danger {
            background-color: $alert-danger-bg;
            border-color: $danger;

            span, strong {
                color: $danger;
            }

            mat-icon:before {
                color: $danger;
            }
        }

        &.alert-warning {
            border-color: $state-warning-text;
            background-color: $alert-warning-bg;

            span, strong {
                color: $state-warning-text;
            }
        }

        &.alert-success {
            border-color: $rise-green;

            span, strong {
                color: $rise-green;
            }
        }

        &.alert-info {
            border-color: $rise-blue;
            background-color: $state-info-bg;

            span, strong {
                color: $rise-blue;
            }

            svg {
                fill: $rise-blue;
            }
        }
    }
}
