// Note: Core Cleanup

// @mixin alert-variant($background, $border, $text-color, $link-text-color: darken($text-color, 10%) ) {
//   background-color: $background;
//   border-color: $border;
//   color: $text-color;
//   hr {
//     border-top-color: darken($border, 5%);
//   }
//   .alert-link {
//     color: $link-text-color;
//   }
// }



@mixin nav-divider($color: $gray-200, $margin-top: (($line-height-computed / 2) - 1)) {
  height: 1px;
  margin: $margin-top 0;
  overflow: hidden;
  background-color: $color;
}

@mixin button-variant($color, $background, $border, $border-active: darken($border, 12%), $bg-active: darken($background, 8%), $border-disabled: $border) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:hover,
  &:focus,
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 5%);
    border-color: darken($border, 12%);
    outline: 0;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: $background;
      border-color: $border-disabled;
    }
  }
}

@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius, $font-weight) {
  padding: $padding-vertical $padding-horizontal;
  font-size: $font-size;
  line-height: $line-height;
  border-radius: $border-radius;
  font-weight: $font-weight;
}

@mixin label-variant($color, $bg-color-hover: darken($color, 10%)) {
  background-color: $color;
  &[href] {
    &:hover,
    &:focus {
      background-color: $bg-color-hover;
    }
  }
}