.app-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-header-title, .madero-style .app-header-title {
  margin: 0;
  text-align: left;
  width: 100%;

  > p {
    font-size: 1.8rem;
    margin-bottom: 0;
  }
}


@media (min-width: $screen-sm) {
  .app-header {
    flex-direction: row;
  }
  .app-header-title {
    width: auto;
  }
}