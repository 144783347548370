.grid {
  display: grid;
  grid-template-columns: auto;

  &.grid-inner-borders {
    > .grid-cell {
      border-top: 1px solid $gray-400;

      &:nth-child(-n + 1) {
        border-top: none;
      }
    }
  }

  &.grid-align-center {
    align-items: center;
  }
}

.grid-col-2 {
  grid-template-columns: repeat(2, 1fr);

  &.grid-inner-borders {
    > .grid-cell {
      border-left: 1px solid $gray-400;

      &:nth-child(-n + 2) {
        border-top: none;
      }
      &:nth-child(2n + 1) {
        border-left: none;
      }
    }
  }
}

.grid-col-2-1 {
  grid-template-columns: 2fr 1fr;
}

.grid-xs-col-2 {
  @media(min-width: $screen-xs) {
    grid-template-columns: repeat(2, 1fr);

    &.grid-inner-borders {
      > .grid-cell {
        border-left: 1px solid $gray-400;

        &:nth-child(-n + 2) {
          border-top: none;
        }
        &:nth-child(2n + 1) {
          border-left: none;
        }
      }
    }
  }
}

.grid-md-col-2 {
  @media(min-width: $screen-md) {
    grid-template-columns: repeat(2, 1fr);

    &.grid-inner-borders {
      > .grid-cell {
        border-left: 1px solid $gray-400;

        &:nth-child(-n + 2) {
          border-top: none;
        }
        &:nth-child(2n + 1) {
          border-left: none;
        }
      }
    }
  }
}

.grid-lg-col-2-1 {
  @media(min-width: $screen-lg) {
    grid-template-columns: 2fr 1fr;
  }
}

.grid-md-col-3 {
  @media(min-width: $screen-md) {
    grid-template-columns: repeat(3, 1fr);

    &.grid-inner-borders {
      > .grid-cell {
        border-left: 1px solid $gray-400;

        &:nth-child(-n + 3) {
          border-top: none;
        }
        &:nth-child(3n + 1) {
          border-left: none;
        }
      }
    }
  }
}

.grid-xs-col-4 {
  @media(min-width: $screen-xs) {
    grid-template-columns: repeat(4, 1fr);

    &.grid-inner-borders {
      > .grid-cell {
        border-left: 1px solid $gray-400;

        &:nth-child(-n + 4) {
          border-top: none;
        }
        &:nth-child(4n + 3) {
          border-left: 1px solid $gray-400;
        }
      }
    }
  }
}

.grid-md-col-4 {
  @media(min-width: $screen-md) {
    grid-template-columns: repeat(4, 1fr);

    &.grid-inner-borders {
      > .grid-cell {
        border-left: 1px solid $gray-400;

        &:nth-child(-n + 4) {
          border-top: none;
        }
        &:nth-child(4n + 3) {
          border-left: 1px solid $gray-400;
        }
      }
    }
  }
}

.grid-md-gap-4 {
  @media(min-width: $screen-md) {
    gap: 1.5rem;
  }
}
