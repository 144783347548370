@media (min-width: $screen-sm) {
  .document-component-container {
    --document-component-body-height: calc(100vh - var(--body-height) - var(--component-header-height));
  }
}

@media (max-width: $screen-sm) {
  .document-component-container {
    --document-component-body-height: calc(100vh - var(--body-height));
  }
}

.document-component-settings {
  height: var(--document-component-body-height);
}

.document-source-container {

  label {
    margin-bottom: 10px;
  }

  .source-button {
    display: flex;
    align-items: center;
    background-color: $white;
    border: 1px solid $gray-500;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 10px;
    text-decoration: none;
    color: $black;
    transition: background-color 0.3s;
    height: 70px;
    cursor: pointer;

    &:hover {
      background-color: $gray-100;
    }

    img {
      width: 24px;
      height: 24px;
      margin-right: 20px;
    }

    span {
      flex-grow: 1;
    }

    streamline-icon {
      margin-right: 20px;
    }

    .fa-chevron-right {
      font-size: 10px;
      color: $black;
    }
  }
}

.provider-connected-file-info {
  .file-icon {
    font-size: 20px;
    color: $red-300;
    margin-right: 10px;
  }
}

.document-error-container {
  display: flex;
  align-items: flex-start;
  padding: 20px;
  border: 1px solid $red-100;
  border-radius: 4px;
  background-color: $red-25;

  .error-icon {
    fill: $brand-danger;
  }
}
