.provider-file-error {
  display: flex;
  align-items: flex-start;
  padding: 20px;
  border: 1px solid $red-100;
  border-radius: 4px;
  background-color: $red-25;

  .error-icon {
    fill: $brand-danger;
  }
}
