.refresh-spin {
  -webkit-animation: spin 2s infinite linear;
     -moz-animation: spin 2s infinite linear;
       -o-animation: spin 2s infinite linear;
          animation: spin 2s infinite linear;
}

@-moz-keyframes spin {
  0% { -moz-transform: rotate(0deg); }
  100% { -moz-transform: rotate(359deg); }
}
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(359deg); }
}
@-o-keyframes spin {
  0% { -o-transform: rotate(0deg); }
  100% { -o-transform: rotate(359deg); }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.spinner-backdrop {
  height: 100%;
  width: 100%;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  background: $glow-750;
}

ul, .inner-box, .panel, .scrollable-list, .modal-dialog, .attribute-editor-component {
  .spinner-backdrop {
    //Absolute position for tables/lists/panels using rv-spinner
    position: absolute;
  }
}
