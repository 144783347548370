.video-component-container {
  --video-component-settings-height: 85px;
}

@media (min-width: $screen-sm) {
  .video-component-container {
    --video-component-list-button-bar-height: 165px;
    --video-component-body-height: calc(100vh - var(--body-height) - var(--component-header-height));
  }
}

@media (max-width: $screen-sm) {
  .video-component-container {
    --video-component-list-button-bar-height: 170px;
    --video-component-body-height: calc(100vh - var(--body-height));
  }
}

.video-component-settings {
  height: var(--video-component-settings-height);

  .video-component-volume + p {
    font-size: 12px;
  }
}

.video-component-list {
  height: calc(var(--video-component-body-height) - var(--video-component-list-button-bar-height));

  &.not-empty {
    height: calc(var(--video-component-body-height) - var(--video-component-settings-height) - var(--video-component-list-button-bar-height) - 20px);
  }

}
