.rise-playlist-container, .presentation-selector-container, .rise-data-table-container {
  --playlist-button-bar-height: 90px;
  --playlist-selected-title-height: 50px;
  --selector-search-box-height: 60px;
}

@media (min-width: $screen-sm) {
  .rise-playlist-container, .presentation-selector-container, .rise-data-table-container {
    --selector-body-height: calc(100vh - var(--body-height) - var(--component-header-height));
  }
}

@media (max-width: $screen-sm) {
  .rise-playlist-container, .presentation-selector-container, .rise-data-table-container {
    --selector-body-height: calc(100vh - var(--body-height));
  }
}

.rise-playlist-container {
  --playlist-button-bar-height: 40px;
  height: calc(var(--selector-body-height) - var(--playlist-selected-title-height));
}

.rise-data-table-container {
  --playlist-button-bar-height: 110px;
}

.rise-playlist-container, .presentation-selector-container, .rise-data-table-container {
  --duration-group-offset: 29px;

  &.attribute-editor-component {
    margin-top: 0;
  }

.templates-list {
  height: var(--playlist-body-height);
  overflow-x: hidden;
  overflow-y: auto;

  .templates-list-empty {
    margin: 20px;

    h2 {
      font-size: 1em;
      font-weight: bold;
      padding: 20px 0 0 0;
      margin-top: 0;
    }

    p {
      font-size: 0.75em;
      line-height: 0.8;
      padding-bottom: 20px;
    }

    img {
      margin: 0 auto;
    }
  }
}

.templates-selector {
  height: calc(var(--selector-body-height) - var(--playlist-selected-title-height) - var(--playlist-button-bar-height));
  padding-top: 0px;
  overflow-x: hidden;
  overflow-y: auto;

  &.active-search {
    height: calc(var(--selector-body-height) - var(--playlist-selected-title-height) - var(--selector-search-box-height) - var(--playlist-button-bar-height));
  }
}

.playlist-templates-title {
  @extend .border-bottom;

  height: var(--playlist-selected-title-height);
  margin: 0px 20px 0px;

  &.active-search {
    border: none;
  }

  label {
    padding: 20px 0 0 0;
  }
}

.templates-selector-search-box .input-group {
  height: var(--selector-search-box-height);
  padding: 0px 0px 20px;
  margin: 0px 20px 0px;
}

.templates-row {
  @extend .flex-row;
  @extend .border-bottom;

  min-height: 45px;

  @media (max-width: $screen-sm) {
    min-height: 40px;
  }

  margin-left: 20px;
  margin-right: 20px;

  .rv-sortable-handle {
    streamline-icon {
      svg {
        height: 20px;

        @media (max-width: $screen-sm) {
          height: 18px;
        }
      }
    }
  }

  .edit-properties {
    min-width: 30px;
  }

}

.templates-name {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 20px;
}

.templates-status {
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0px;
  color: $gray-500;
}

.templates-status-revised {
  color: $danger;
}

.templates-selector {
  & .templates-row label {
    cursor: pointer;
  }
}

.templates-selector-show {
  animation-name: slide-in-right;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.templates-selector-hide {
  animation-name: slide-out-right;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.templates-selector-name {
  display: inline-block;
}

.templates-selector-no-results {
  font-size: 0.9em;
  margin: 20px;

  & a {
    color: $rise-blue;
  }

}

.playlist-action-button-bar {
  height: var(--playlist-button-bar-height);
  margin: 0 20px;
  background: $white;

  border-top: 1px solid $gray-500;

  .add-templates {
    padding: 27px 0 0 0;
  }

  & > div {
    background: $white;
  }
}

}

.templates-row {
  &.draggable-mirror {
    @extend .border-bottom;

    min-height: 45px;
    display: flex;
    align-items: center;
    background: $pale-glow-850;
  }
}

.playlist-item-container .timeline-label {
  display: none;
}
