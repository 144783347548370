/* Alignment classes */
.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.justify {
  text-align: justify;
}