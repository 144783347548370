@use "sass:map";
.grid-selector {
  max-height: calc(100vh - 240px);
}

.grid-selector-list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 24px;
  padding: 4px;

  @media screen and (max-width: 1386px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (max-width: 1166px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    padding: 0;
  }
}

.grid-selector-list-item {
  position: relative;
  min-width: 196px;
  height: 186px;
  align-self: center;
  overflow: hidden;
  cursor: pointer;
  fill: $rise-blue;
  border: 1px solid $gray-200;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;

  &.folder {
    height: 52px;
    border-radius: 6px;
  }

  &.selected {
    border-color: rgba(#1EA861, .2);
    border-width: 5px;

    &.folder {
      height: 58px;

      .list-item::after {
        margin-left: -16px;
      }
    }
  }

  div {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    &.list-item {
      position: relative;
      border-radius: 4px;
      border: 2px solid transparent;
  
      &::after {
        content: '';
        display: none;
        width: 100%;
        height: 100%;
        position:absolute;
      }

      &::after {
        opacity: 0.2;
        background-color: map.get($rise-green-palette, 600);
      }
    }

    .image-container {
      overflow: hidden;
      border-radius: 4px;

      &:not(.folder) {
        flex: 1 1 100%;
        align-items: center;
      }
    }

    &.list-item--selected {
      border-color: #1EA861;
      &.list-item {
        &::after {
          display: block;
          overflow: hidden;
        }
      }
    }

    &.placeholder {
      .image-container {
        justify-content: center;
      }
    }
  }

  .list-item-icon {
    width: 33%;
    margin-top: -20px;
  }

  .list-type_image {
    img {
      max-width: 100%;
      max-height: 100%;
      vertical-align: middle;
    }
  }

  .list-item--throttled {
    .image-container {
      &::before {
        display: block;
        background: transparentize($danger, .5);
      }
      &::after {
        content: '\f05e';
        display: block;
        top: 0;
      }
    }

    .list-item-label {
      background: transparentize($danger, .65);
      color: $text-inverse;
    }
  }

  .list-item--disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .list-item-label {
    line-height: 20px;
    padding: 16px;
    overflow: hidden;
    font-size: calc(10px + 0.15vw);
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    flex: 0 0 70px;
    background-color: $white;
    border-radius: 8px;
  }

  .list-item_folder {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    padding: 12px 16px;
    background-color: $gray-100;
    
    .list-item-label {
      background: none;
      padding: 0;
      flex: 1;
    }
  }
}

.list-item-placeholder {
  visibility: hidden;
  height:         0 !important;
  margin-top:     0 !important;
  margin-bottom:  0 !important;
  padding-top:    0 !important;
  padding-bottom: 0 !important;
  border:         0 !important;
}
